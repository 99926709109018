// src/assets/styles/global.scss
@use "variables" as *;
@use "mixins" as *;
@use "fonts";

html,
body {
  margin: 0;
  padding: 0;
  background-color: $background-color;
  color: $text-color;
  font-family: "Barlow Semi Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
  font-family: inherit;
}
// src/assets/styles/fonts.scss

// Thin (100)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Thin.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-ThinItalic.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

// Extra Light (200)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-ExtraLight.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

// Light (300)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-LightItalic.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// Regular (400)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Italic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// Medium (500)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-MediumItalic.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

// Semi Bold (600)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

// Bold (700)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-BoldItalic.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

// Extra Bold (800)
@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-ExtraBoldItalic.ttf")
    format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

// Black (900)
@font-face {  
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-BlackItalic.ttf")
    format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Barlow

// Light (300)
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-LightItalic.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// Regular (400)
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Italic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// Medium (500) 
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-MediumItalic.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

// Semi Bold (600)
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Bold (700)
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
// src/assets/styles/mixins.scss
@use "variables" as *;
// Webkit scrollbar styling
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($secondary-color, 0.3);
  border-radius: 4px;

  &:hover {
    background-color: rgba($secondary-color, 0.5);
  }
}

// Firefox scrollbar styling (version 64+)
* {
  scrollbar-width: thin;
  scrollbar-color: rgba($secondary-color, 0.3) transparent;
}

// Dark theme support using Tailwind's dark mode
@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.3); // gray-400 with opacity

    &:hover {
      background-color: rgba(156, 163, 175, 0.5);
    }
  }

  * {
    scrollbar-color: rgba(156, 163, 175, 0.3) transparent;
  }
}

// For when dark mode is toggled via class
.dark {
  ::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.3);

    &:hover {
      background-color: rgba(156, 163, 175, 0.5);
    }
  }

  * {
    scrollbar-color: rgba(156, 163, 175, 0.3) transparent;
  }
}
